import {
  SET_COUNTRIES,
  SET_TIMEZONES,
  RESET_GLOBAL_DATA
} from "../actions/actionTypes";

const initialState = {
  timezones: {},
  countries: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMEZONES:
      return {
        ...state,
        timezones: action.timezones,
      };
      case SET_COUNTRIES:
        return {
          ...state,
          countries: action.countries,
        };
    case RESET_GLOBAL_DATA:
      return initialState;
    default:
      return state;
  }
};