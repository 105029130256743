import {
    SET_TRADEABLE_CATEGORIES,
    SET_TRADEABLE_CATEGORY,
    RESET_TRADEABLE_CATEGORY,
  } from "../actions/actionTypes";
  
  const initialState = {
    tradeableCategories: [],
    tradeableCategoriesObject: {},
    tradeableCategory: []
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_TRADEABLE_CATEGORIES:
        return {
          ...state,
          tradeableCategories: action.tradeableCategories.data,
          tradeableCategoriesObject: action.tradeableCategories,
        };
      case SET_TRADEABLE_CATEGORY:
        return {
          ...state,
          accountCategory: action.tradeableCategory.data,
        };
      case RESET_TRADEABLE_CATEGORY:
        return initialState;
      default:
        return state;
    }
  };