import {
  SET_SUPPLIER,
  SET_SUPPLIERS,
} from "../actions/actionTypes";

const initialState = {
  suppliers: [],
  supplierObject: {},
  supplier: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.suppliers.data,
        supplierObject: action.suppliers,
      };
      case SET_SUPPLIER:
        return {
          ...state,
          supplier: action.supplier,
        };

    default:
      return state;
  }
};