import { combineReducers } from "redux";
import auth from "./authReducer";
import businesses from "./businessReducer";
import ui from "./uiReducer";
import accounts from "./accountsReducers";
import accountTypes from "./accountTypesReducers";
import accountCategories from "./accountCategoriesReducers";
import globalData from "./globalDataReducer";
import customers from "./customerReducer";
import journals from "./journalReducer";
import services from "./servicesReducer";
import suppliers from "./suppliersReducer";
import bills from "./billReducer";
import purchaseOrders from "./purchaseOrderReducer";
import billPayments from "./billPaymentReducer";
import tradeables from "./tradeablesReducer";
import tradeableCategories from "./tradeableCategoriesReducer";
import debitNotes from "./debitNoteReducer";
import creditNotes from "./creditNoteReducer";
import expenses from "./expenseReducer";
import invoices from "./invoicesReducer";
import quotes from "./quotesReducer";
import salesOrders from "./salesOrderReducer";
import bankAccounts from "./bankAccountReducer";
import invoicePayments from "./invoicePaymentReducer";
import receipts from "./receiptReducer";
import bankTransactions from "./bankTransactionReducer";
import bankReconciliations from "./bankReconciliationReducer";
import bankTransactionMatches from "./bankTransactionMatchReducer";

const rootReducer = combineReducers({
  auth,
  accounts,
  accountTypes,
  accountCategories,
  businesses,
  ui,
  globalData,
  customers,
  tradeables,
  services,
  suppliers,
  tradeableCategories,
  bills,
  purchaseOrders,
  billPayments,
  invoices,
  debitNotes,
  creditNotes,
  expenses,
  quotes,
  salesOrders,
  bankAccounts,
  invoicePayments,
  receipts,
  bankTransactions,
  bankReconciliations,
  bankTransactionMatches,
  journals,
});

export default rootReducer;
