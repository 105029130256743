import {
  SET_BANK_TRANSACTION_MATCH,
  SET_BANK_TRANSACTION_MATCHES,
} from "../actions/actionTypes";

const initialState = {
  bankTransactionMatches: [],
  bankTransactionMatchObject: {},
  bankTransactionMatch: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BANK_TRANSACTION_MATCHES:
      return {
        ...state,
        bankTransactionMatches: action.bankTransactionMatches.data,
        bankTransactionMatchObject: action.bankTransactionMatches,
      };
    case SET_BANK_TRANSACTION_MATCH:
      return {
        ...state,
        bankTransactionMatch: action.bankTransactionMatch,
      };

    default:
      return state;
  }
};
