import {
  RESET_AUTH,
  SET_BUSINESSES,
  SET_BUSINESS_SLUG,
  SET_BUSINESS,
  SET_BUSINESS_LOGO,
} from "../actions/actionTypes";

const initialState = {
  businesses: [],
  businessObject: {},
  businessSlug: "",
  business: {},
  businessLogo: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSINESSES:
      return {
        ...state,
        businesses: action.businesses.data,
        businessObject: action.businesses,
      };
    case SET_BUSINESS_SLUG:
      return {
        ...state,
        businessSlug: action.businessSlug,
      };
    case SET_BUSINESS_LOGO:
      return {
        ...state,
        businessLogo: action.businessLogo,
      };
    case SET_BUSINESS:
      return {
        ...state,
        business: action.business,
      };

    case RESET_AUTH:
      return initialState;
    default:
      return state;
  }
};
