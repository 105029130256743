import { SET_RECEIPT, SET_RECEIPTS } from "../actions/actionTypes";

const initialState = {
  receipts: [],
  receiptObject: {},
  receipt: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RECEIPTS:
      return {
        ...state,
        receipts: action.receipts.data,
        receiptObject: action.receipts,
      };
    case SET_RECEIPT:
      return {
        ...state,
        receipt: action.receipt,
      };

    default:
      return  state
  }
};
