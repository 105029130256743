import {
  CUSTOMERS_UI_START_LOADING,
  CUSTOMERS_UI_STOP_LOADING,
  RESET_UI,
  UI_START_LOADING,
  UI_STOP_LOADING,
  ONBOARDING_UI_START_LOADING,
  ONBOARDING_UI_STOP_LOADING,
  ACCOUNT_CATEGORIES_UI_START_LOADING,
  ACCOUNT_CATEGORIES_UI_STOP_LOADING,
  ACCOUNTS_UI_START_LOADING,
  ACCOUNTS_UI_STOP_LOADING,
  SUPPLIERS_UI_START_LOADING,
  SUPPLIERS_UI_STOP_LOADING,
  BILLS_UI_START_LOADING,
  BILLS_UI_STOP_LOADING,
  PURCHASE_ORDERS_UI_START_LOADING,
  PURCHASE_ORDERS_UI_STOP_LOADING,
  INVOICES_UI_START_LOADING,
  INVOICES_UI_STOP_LOADING,
  QUOTES_UI_START_LOADING,
  QUOTES_UI_STOP_LOADING,
  TRADEABLES_UI_START_LOADING,
  TRADEABLES_UI_STOP_LOADING,
  DEBIT_NOTES_UI_START_LOADING,
  DEBIT_NOTES_UI_STOP_LOADING,
  CREDIT_NOTES_UI_START_LOADING,
  CREDIT_NOTES_UI_STOP_LOADING,
  EXPENSES_UI_START_LOADING,
  EXPENSES_UI_STOP_LOADING,
  SALES_ORDERS_UI_START_LOADING,
  SALES_ORDERS_UI_STOP_LOADING,
  BILL_PAYMENTS_UI_START_LOADING,
  BILL_PAYMENTS_UI_STOP_LOADING,
  BANK_ACCOUNTS_UI_STOP_LOADING,
  BANK_ACCOUNTS_UI_START_LOADING,
  INVOICE_PAYMENTS_UI_START_LOADING,
  INVOICE_PAYMENTS_UI_STOP_LOADING,
  BANK_TRANSACTIONS_UI_START_LOADING,
  BANK_TRANSACTIONS_UI_STOP_LOADING,
  BANK_RECONCILIATION_UI_STOP_LOADING,
  BANK_TRANSACTION_MATCH_UI_STOP_LOADING,
  BANK_TRANSACTION_MATCH_UI_START_LOADING,
  JOURNALS_UI_START_LOADING,
  JOURNALS_UI_STOP_LOADING,
  RECEIPTS_UI_START_LOADING,
  RECEIPTS_UI_STOP_LOADING,
  REPORTS_UI_START_LOADING,
  REPORTS_UI_STOP_LOADING
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isCustomersUiLoading: false,
  isOnboardingUiLoading: false,
  isAccountCategoriesUiLoading: false,
  isAccountsUiLoading: false,
  isSuppliersUiLoading: false,
  isBillsUiLoading: false,
  isPurchaseOrdersUiLoading: false,
  isSalesOrdersUiLoading: false,
  isTradeablesUiLoading: false,
  isCreditNotesUiLoading: false,
  isDebitNotesUiLoading: false,
  isExpensesUiLoading: false,
  isInvoicesUiLoading: false,
  isQoutesUiLoading: false,
  isBillPaymentsUiLoading: false,
  isBanksUiLoading: false,
  isInvoicePaymentsUiLoading: false,
  isBankAccountsUiLoading: false,
  isBankTransactionsUiLoading: false,
  isBankReconciliationsUiLoading: false,
  isBankTransactionMatchesUiLoading: false,
  isJournalsUiLoading: false,
  isReceiptsUiLoading: false,
  isReportsUiLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UI_STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case CUSTOMERS_UI_START_LOADING:
      return {
        ...state,
        isCustomersUiLoading: true,
      };
    case CUSTOMERS_UI_STOP_LOADING:
      return {
        ...state,
        isCustomersUiLoading: false,
      };

    case RESET_UI:
      return initialState;

    case ONBOARDING_UI_START_LOADING:
      return {
        ...state,
        isOnboardingUiLoading: true,
      };
    case ONBOARDING_UI_STOP_LOADING:
      return {
        ...state,
        isOnboardingUiLoading: false,
      };

    case ACCOUNT_CATEGORIES_UI_START_LOADING:
      return {
        ...state,
        isAccountCategoriesUiLoading: true,
      };
    case ACCOUNT_CATEGORIES_UI_STOP_LOADING:
      return {
        ...state,
        isAccountCategoriesUiLoading: false,
      };

    case ACCOUNTS_UI_START_LOADING:
      return {
        ...state,
        isAccountsUiLoading: true,
      };
    case ACCOUNTS_UI_STOP_LOADING:
      return {
        ...state,
        isAccountsUiLoading: false,
      };

    case SUPPLIERS_UI_START_LOADING:
      return {
        ...state,
        isSuppliersUiLoading: true,
      };
    case SUPPLIERS_UI_STOP_LOADING:
      return {
        ...state,
        isSuppliersUiLoading: false,
      };

    case BILLS_UI_START_LOADING:
      return {
        ...state,
        isBillsUiLoading: true,
      };
    case BILLS_UI_STOP_LOADING:
      return {
        ...state,
        isBillsUiLoading: false,
      };

    case PURCHASE_ORDERS_UI_START_LOADING:
      return {
        ...state,
        isPurchaseOrdersUiLoading: true,
      };
    case PURCHASE_ORDERS_UI_STOP_LOADING:
      return {
        ...state,
        isPurchaseOrdersUiLoading: false,
      };

    case INVOICES_UI_START_LOADING:
      return {
        ...state,
        isInvoicesUiLoading: true,
      };
    case INVOICES_UI_STOP_LOADING:
      return {
        ...state,
        isInvoicesUiLoading: false,
      };

    case QUOTES_UI_START_LOADING:
      return {
        ...state,
        isQuotesUiLoading: true,
      };
    case QUOTES_UI_STOP_LOADING:
      return {
        ...state,
        isQuotesUiLoading: false,
      };

    case TRADEABLES_UI_START_LOADING:
      return {
        ...state,
        isTradeablesUiLoading: true,
      };
    case TRADEABLES_UI_STOP_LOADING:
      return {
        ...state,
        isTradeablesUiLoading: false,
      };

    case DEBIT_NOTES_UI_START_LOADING:
      return {
        ...state,
        isDebitNotesUiLoading: true,
      };
    case DEBIT_NOTES_UI_STOP_LOADING:
      return {
        ...state,
        isDebitNotesUiLoading: false,
      };

    case CREDIT_NOTES_UI_START_LOADING:
      return {
        ...state,
        isCreditNotesUiLoading: true,
      };
    case CREDIT_NOTES_UI_STOP_LOADING:
      return {
        ...state,
        isCreditNotesUiLoading: false,
      };

    case EXPENSES_UI_START_LOADING:
      return {
        ...state,
        isExpensesUiLoading: true,
      };
    case EXPENSES_UI_STOP_LOADING:
      return {
        ...state,
        isExpensesUiLoading: false,
      };

    case SALES_ORDERS_UI_START_LOADING:
      return {
        ...state,
        isSalesOrdersUiLoading: true,
      };
    case SALES_ORDERS_UI_STOP_LOADING:
      return {
        ...state,
        isSalesOrdersUiLoading: false,
      };

    case BILL_PAYMENTS_UI_START_LOADING:
      return {
        ...state,
        isBillPaymentsUiLoading: true,
      };
    case BILL_PAYMENTS_UI_STOP_LOADING:
      return {
        ...state,
        isBillPaymentsUiLoading: false,
      };

    case BANK_ACCOUNTS_UI_START_LOADING:
      return {
        ...state,
        isBankAccountsUiLoading: true,
      };
    case BANK_ACCOUNTS_UI_STOP_LOADING:
      return {
        ...state,
        isBankAccountsUiLoading: false,
      };

    case INVOICE_PAYMENTS_UI_START_LOADING:
      return {
        ...state,
        isInvoicePaymentsUiLoading: true,
      };
    case INVOICE_PAYMENTS_UI_STOP_LOADING:
      return {
        ...state,
        isInvoicePaymentsUiLoading: false,
      };

    case BANK_TRANSACTIONS_UI_START_LOADING:
      return {
        ...state,
        isBankTransactionsUiLoading: true,
      };
    case BANK_TRANSACTIONS_UI_STOP_LOADING:
      return {
        ...state,
        isBankTransactionsUiLoading: false,
      };

    case BANK_ACCOUNTS_UI_START_LOADING:
      return {
        ...state,
        isBankReconciliationsUiLoading: true,
      };
    case BANK_RECONCILIATION_UI_STOP_LOADING:
      return {
        ...state,
        isBankReconciliationsUiLoading: false,
      };

    case BANK_TRANSACTION_MATCH_UI_START_LOADING:
      return {
        ...state,
        isBankTransactionMatchesUiLoading: true,
      };
    case BANK_TRANSACTION_MATCH_UI_STOP_LOADING:
      return {
        ...state,
        isBankTransactionMatchesUiLoading: false,
      };

    case JOURNALS_UI_START_LOADING:
      return {
        ...state,
        isJournalsUiLoading: true,
      };
    case JOURNALS_UI_STOP_LOADING:
      return {
        ...state,
        isJournalsUiLoading: false,
      };
    case RECEIPTS_UI_START_LOADING:
      return {
        ...state,
        isReceiptsUiLoading: true,
      };
    case RECEIPTS_UI_STOP_LOADING:
      return {
        ...state,
        isReceiptsUiLoading: false,
      };
    case REPORTS_UI_START_LOADING:
      return {
        ...state,
        isReportsUiLoading: true,
      };
    case REPORTS_UI_STOP_LOADING:
      return {
        ...state,
        isReportsUiLoading: false,
      };

    default:
      return state;
  }
};
