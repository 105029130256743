import {
  SET_BANK_TRANSACTION,
  SET_BANK_TRANSACTIONS,
} from "../actions/actionTypes";

const initialState = {
  bankTransactions: [],
  bankTransactionObject: {},
  bankTransaction: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BANK_TRANSACTIONS:
      return {
        ...state,
        bankTransactions: action.bankTransactions.data,
        bankTransactionObject: action.bankTransactions,
      };
    case SET_BANK_TRANSACTION:
      return {
        ...state,
        bankTransaction: action.bankTransaction,
      };

    default:
      return state;
  }
};
