import {
  SET_ACCOUNT_TYPES,
  SET_ACCOUNT_TYPE,
  RESET_ACCOUNT_TYPES,
} from "../actions/actionTypes";

const initialState = {
  accountTypes: {},
  accountType: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_TYPES:
      return {
        ...state,
        accountTypes: action.accountTypes,
      };
    case SET_ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.accountType.data,
      };
    case RESET_ACCOUNT_TYPES:
      return initialState;
    default:
      return state;
  }
};