import {
  RESET_AUTH,
  SET_IDENTITY,
} from "../actions/actionTypes";

const initialState = {
  identity: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IDENTITY:
      return {
        ...state,
        identity: action.identity,
      };
    case RESET_AUTH:
      return initialState;
    default:
      return state;
  }
};
