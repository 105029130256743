import {
  SET_ACCOUNT_CATEGORIES,
  SET_ACCOUNT_CATEGORY,
  RESET_ACCOUNT_CATEGORIES,
} from "../actions/actionTypes";

const initialState = {
  accountCategories: [],
  accountCategoriesObject: {},
  accountCategory: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_CATEGORIES:
      return {
        ...state,
        accountCategories: action.accountCategories.data,
        accountCategoriesObject: action.accountCategories,
      };
    case SET_ACCOUNT_CATEGORY:
      return {
        ...state,
        accountCategory: action.accountCategory.data,
      };
    case RESET_ACCOUNT_CATEGORIES:
      return initialState;
    default:
      return state;
  }
};