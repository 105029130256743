// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { configureStore, applyMiddleware, compose } from "@reduxjs/toolkit";
// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const store = configureStore({reducer:rootReducer}, {}, composeEnhancers(applyMiddleware(...middleware)))

export { store }
