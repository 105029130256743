import {
  SET_EXPENSE,
  SET_EXPENSES,
} from "../actions/actionTypes";

const initialState = {
  expenses: [],
  expenseObject: {},
  expense: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPENSES:
      return {
        ...state,
        expenses: action.expenses.data,
        expenseObject: action.expenses,
      };
    case SET_EXPENSE:
      return {
        ...state,
        expense: action.expense,
      };

    default:
      return state;
  }
};
