import React, { lazy, Profiler } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const AppLayout = lazy(() => import("../layouts"));
const Dashboard = lazy(() => import("../views/geeboard/dashboard"));

// Accounts
const AccountCharts = lazy(() => import("../views/geeboard/account/accountCharts/index.js"));
const AccountCategories = lazy(() => import("../views/geeboard/account/accountCategories/index.js"));

// Customers
const Customers = lazy(() => import("../views/geeboard/customers/customers"));
const SingleCustomer = lazy(() => import("../views/geeboard/customers/customers/view/index"));

const CreditNotes = lazy(() => import("../views/geeboard/customers/creditNotes"));
const CreateCreditNotes = lazy(() => import("../views/geeboard/customers/creditNotes/create"));
const SingleCreditNote = lazy(() => import("../views/geeboard/customers/creditNotes/view/index"));

const SalesOrders = lazy(() => import("../views/geeboard/customers/salesOrder"));
const CreateSalesOrders = lazy(() => import("../views/geeboard/customers/salesOrder/create"));
const SingleSalesOrder = lazy(() => import("../views/geeboard/customers/salesOrder/view/index"));

const Receipts = lazy(() => import("../views/geeboard/customers/receipts"));
const CreateReceipt = lazy(() => import("../views/geeboard/customers/receipts/create"));
const SingleReceipt = lazy(() => import("../views/geeboard/customers/receipts/view/index"));

const Invoices = lazy(() => import("../views/geeboard/customers/invoices"));
const CreateInvoice = lazy(() => import("../views/geeboard/customers/invoices/create"));
const SingleInvoice = lazy(() => import("../views/geeboard/customers/invoices/view/index"));

const Qoutes = lazy(() => import("../views/geeboard/customers/quotes"));
const CreateQuote = lazy(() => import("../views/geeboard/customers/quotes/create"));
const SingleQuote = lazy(() => import("../views/geeboard/customers/quotes/view/index"));

const InvoicePaymentHistory = lazy(() => import("../views/geeboard/customers/invoicePaymentsHistory"));
const SingleInvoicePaymentHistory = lazy(() => import("../views/geeboard/customers/invoicePaymentsHistory/view/index"));

// SUPPLIERS
const Suppliers = lazy(() => import("../views/geeboard/suppliers"));
const SingleSupplier = lazy(() => import("../views/geeboard/suppliers/SingleSupplier"));

const PurchaseOrders = lazy(() => import("../views/geeboard/suppliers/purchaseOrder"));
const CreatePurchaseOrder = lazy(() => import("../views/geeboard/suppliers/purchaseOrder/create"));
const SinglePurchaseOrder = lazy(() => import("../views/geeboard/suppliers/purchaseOrder/SinglePurchase.js"));

const Bills = lazy(() => import("../views/geeboard/suppliers/bills"));
const CreateBill = lazy(() => import("../views/geeboard/suppliers/bills/create"));
const SingleBill = lazy(() => import("../views/geeboard/suppliers/bills/view/index"));

const DebitNotes = lazy(() => import("../views/geeboard/suppliers/debitNotes"));
const CreateDebitNote = lazy(() => import("../views/geeboard/suppliers/debitNotes/create"));
const SingleDebitNote = lazy(() => import("../views/geeboard/suppliers/debitNotes/view"));

const PaymentsHistory = lazy(() => import("../views/geeboard/suppliers/paymentsHistory"));

const Expenses = lazy(() => import("../views/geeboard/suppliers/expenses"));
const CreateExpense = lazy(() => import("../views/geeboard/suppliers/expenses/create"));
const SingleExpense = lazy(() => import("../views/geeboard/suppliers/expenses/view"));

const Journals = lazy(() => import("../views/geeboard/journals"));
const CreateJournal = lazy(() => import("../views/geeboard/journals/create"));
const SingleJournal = lazy(() => import("../views/geeboard/journals/view"));

const Tradeables = lazy(() => import("../views/geeboard/tradeables"));

// BANK
const BankAccounts = lazy(() => import("../views/geeboard/banks/banks"));
const SingleBankAccount = lazy(() => import("../views/geeboard/banks/banks/Bank.js"));
const CreateBankTransaction = lazy(() => import("../views/geeboard/banks/banks/createTransaction.js"));
const MatchBankTransaction = lazy(() => import("../views/geeboard/banks/banks/MatchTransaction.js"));
const BankReconcilations = lazy(() => import("../views/geeboard/banks/reconciliations/index.js"));
const BankReconcilation = lazy(() => import("../views/geeboard/banks/reconciliations/Reconciliation.js"));

// REPORTS
const BalanceSheet = lazy(() => import("../views/geeboard/reports/balanceSheet"));
const CashFlow = lazy(() => import("../views/geeboard/reports/cashflow"));
const ProfitLossStatement = lazy(() => import("../views/geeboard/reports/profitLossStatement"));
const TrialBalance = lazy(() => import("../views/geeboard/reports/trialBalance"));
const JournalReport = lazy(() => import("../views/geeboard/reports/JournalReport.js"));
const GeneralLedger = lazy(() => import("../views/geeboard/reports/generalLedger.js"));

const Onboarding = lazy(() => import("../views/geeboard/onboarding/registerBusiness.js"));
const Settings = lazy(() => import("../views/geeboard/settings"));
const Faqs = lazy(() => import("../views/geeboard/faqs"));
const Profile = lazy(() => import("../views/geeboard/account/companyDetails"));

const Error404 = lazy(() => import("../views/errorPage"));

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Dashboard />} />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/accounts" element={<AccountCharts />} />
          <Route path="/accounts/account-categories" element={<AccountCategories />} />

          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:slug" element={<SingleCustomer />} />

          <Route path="/customers/credit-notes" element={<CreditNotes />} />
          <Route path="/customers/credit-notes/create" element={<CreateCreditNotes />} />
          <Route path="/customers/credit-notes/:slug" element={<SingleCreditNote />} />

          <Route path="/customers/sales-orders" element={<SalesOrders />} />
          <Route path="/customers/sales-orders/create" element={<CreateSalesOrders />} />
          <Route path="/customers/sales-orders/:slug" element={<SingleSalesOrder />} />

          <Route path="/customers/receipts" element={<Receipts />} />
          <Route path="/customers/receipts/create" element={<CreateReceipt />} />
          <Route path="/customers/receipts/:slug" element={<SingleReceipt/>} />

          <Route path="/customers/invoices" element={<Invoices />} />
          <Route path="/customers/invoices/create" element={<CreateInvoice />} />
          <Route path="/customers/invoices/:slug" element={<SingleInvoice />} />

          <Route path="/customers/invoice-payments-history" element={<InvoicePaymentHistory />} />
          <Route path="/customers/invoice-payment-history/:slug" element={<SingleInvoicePaymentHistory />} />

          <Route path="/customers/quotes" element={<Qoutes />} />
          <Route path="/customers/quotes/create" element={<CreateQuote />} />
          <Route path="/customers/quotes/:slug" element={<SingleQuote />} />

          <Route path="/suppliers" element={<Suppliers />} />
          <Route path="/suppliers/:slug" element={<SingleSupplier />} />

          <Route path="/suppliers/purchase-orders" element={<PurchaseOrders />} />
          <Route path="/suppliers/purchase-orders/create" element={<CreatePurchaseOrder />} />
          <Route path="/suppliers/purchase-orders/:slug" element={<SinglePurchaseOrder />} />

          <Route path="/suppliers/bills" element={<Bills />} />
          <Route path="/suppliers/bills/create" element={<CreateBill />} />
          <Route path="/suppliers/bills/:slug" element={<SingleBill />} />

          <Route path="/suppliers/debit-notes" element={<DebitNotes />} />
          <Route path="/suppliers/debit-notes/create" element={<CreateDebitNote />} />
          <Route path="/suppliers/debit-notes/:slug" element={<SingleDebitNote />} />

          <Route path="/suppliers/payments-history" element={<PaymentsHistory />} />

          <Route path="/suppliers/expenses" element={<Expenses />} />
          <Route path="/suppliers/expenses/create" element={<CreateExpense />} />
          <Route path="/suppliers/expenses/:slug" element={<SingleExpense />} />

          <Route path="/journals" element={<Journals />} />
          <Route path="/journals/create" element={<CreateJournal />} />
          <Route path="/journals/:slug" element={<SingleJournal />} />

          <Route path="/bank-accounts" element={<BankAccounts />} />
          <Route path="/bank-accounts/:bank" element={<SingleBankAccount />} />
          <Route path="/bank-accounts/add-transaction/:bankSlug" element={<CreateBankTransaction />} />
          <Route path="/bank-accounts/:bank/:transaction" element={<MatchBankTransaction />} /> 
          <Route path="/bank-reconciliations" element={<BankReconcilations />} /> 
          <Route path="/bank-reconciliation" element={<BankReconcilation />} /> 
          
          <Route path="/reports/balance-sheet" element={<BalanceSheet />} />
          <Route path="/reports/cash-flow" element={<CashFlow />} />
          <Route path="/reports/profit-and-loss-statement" element={<ProfitLossStatement />} />
          <Route path="/reports/trial-balance" element={<TrialBalance />} />
          <Route path="/reports/journal-report" element={<JournalReport />} />
          <Route path="/reports/general-ledger" element={<GeneralLedger />} />

          <Route path="/products-and-services" element={<Tradeables />} />

          <Route path="/settings" element={<Settings />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
