import {
    SET_SERVICE,
    SET_SERVICES,
  } from "../actions/actionTypes";
  
  const initialState = {
    services: [],
    serviceObject: {},
    service: {}
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_SERVICES:
        return {
          ...state,
          services: action.services.data,
          serviceObject: action.services,
        };
        case SET_SERVICE:
          return {
            ...state,
            service: action.service,
          };
  
      default:
        return state;
    }
  };