import {
    SET_ACCOUNTS,
    SET_ACCOUNT,
    RESET_ACCOUNTS,
  } from "../actions/actionTypes";
  
  const initialState = {
    accounts: [],
    accountsObject: {},
    account: []
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_ACCOUNTS:
        return {
          ...state,
          accounts: action.accounts.data,
          accountsObject: action.accounts,
        };
        case SET_ACCOUNT:
          return {
            ...state,
            account: action.account.data,
          };
      case RESET_ACCOUNTS:
        return initialState;
      default:
        return state;
    }
  };