import {
  SET_QUOTE,
  SET_QUOTES,
} from "../actions/actionTypes";

const initialState = {
  quotes: [],
  quoteObject: {},
  quote: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_QUOTES:
      return {
        ...state,
        quotes: action.quotes.data,
        quoteObject: action.quotes,
      };
      case SET_QUOTE:
        return {
          ...state,
          quote: action.quote,
        };

    default:
      return state;
  }
};