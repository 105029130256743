import {
  SET_INVOICE,
  SET_INVOICES,
} from "../actions/actionTypes";

const initialState = {
  invoices: [],
  invoiceObject: {},
  invoice: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICES:
      return {
        ...state,
        invoices: action.invoices.data,
        invoiceObject: action.invoices,
      };
      case SET_INVOICE:
        return {
          ...state,
          invoice: action.invoice,
        };

    default:
      return state;
  }
};