import {
  SET_PURCHASE_ORDER,
  SET_PURCHASE_ORDERS,
} from "../actions/actionTypes";

const initialState = {
  purchaseOrders: [],
  purchaseOrderObject: {},
  purchaseOrder: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: action.purchaseOrders.data,
        purchaseOrderObject: action.purchaseOrders,
      };
    case SET_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrder: action.purchaseOrder,
      };

    default:
      return state;
  }
};
