// UI action types
export const UI_START_LOADING = "UI_START_LOADING";
export const UI_STOP_LOADING = "UI_STOP_LOADING";
export const ACCOUNTS_UI_START_LOADING = "ACCOUNTS_UI_START_LOADING";
export const ACCOUNTS_UI_STOP_LOADING = "ACCOUNTS_UI_STOP_LOADING";
export const ACCOUNT_TYPES_UI_START_LOADING = "ACCOUNT_TYPES_UI_START_LOADING";
export const ACCOUNT_TYPES_UI_STOP_LOADING = "ACCOUNT_TYPES_UI_STOP_LOADING";
export const ACCOUNT_CATEGORIES_UI_STOP_LOADING =
  "ACCOUNT_CATEGORIES_UI_STOP_LOADING";
export const ACCOUNT_CATEGORIES_UI_START_LOADING =
  "ACCOUNT_CATEGORIES_UI_START_LOADING";
export const CUSTOMERS_UI_START_LOADING = "CUSTOMERS_UI_START_LOADING";
export const CUSTOMERS_UI_STOP_LOADING = "CUSTOMERS_UI_STOP_LOADING";
export const JOURNALS_UI_START_LOADING = "JOURNALS_UI_START_LOADING";
export const JOURNALS_UI_STOP_LOADING = "JOURNALS_UI_STOP_LOADING";
export const TRADEABLES_UI_START_LOADING = "TRADEABLES_UI_START_LOADING";
export const TRADEABLES_UI_STOP_LOADING = "TRADEABLES_UI_STOP_LOADING";
export const SERVICES_UI_START_LOADING = "SERVICES_UI_START_LOADING";
export const SERVICES_UI_STOP_LOADING = "SERVICES_UI_STOP_LOADING";
export const SUPPLIERS_UI_START_LOADING = "SUPPLIERS_UI_START_LOADING";
export const SUPPLIERS_UI_STOP_LOADING = "SUPPLIERS_UI_STOP_LOADING";
export const BILLS_UI_START_LOADING = "BILLS_UI_START_LOADING";
export const BILLS_UI_STOP_LOADING = "BILLS_UI_STOP_LOADING";
export const PURCHASE_ORDERS_UI_START_LOADING =
  "PURCHASE_ORDERS_UI_START_LOADING";
export const PURCHASE_ORDERS_UI_STOP_LOADING =
  "PURCHASE_ORDERS_UI_STOP_LOADING";
export const BILL_PAYMENTS_UI_START_LOADING = "BILL_PAYMENTS_UI_START_LOADING";
export const BILL_PAYMENTS_UI_STOP_LOADING = "BILL_PAYMENTS_UI_STOP_LOADING";
export const INVOICE_PAYMENTS_UI_START_LOADING =
  "INVOICE_PAYMENTS_UI_START_LOADING";
export const INVOICE_PAYMENTS_UI_STOP_LOADING =
  "INVOICE_PAYMENTS_UI_STOP_LOADING";
export const INVOICES_UI_START_LOADING = "INVOICES_UI_START_LOADING";
export const INVOICES_UI_STOP_LOADING = "INVOICES_UI_STOP_LOADING";
export const REPORTS_UI_START_LOADING = "REPORTS_UI_START_LOADING";
export const REPORTS_UI_STOP_LOADING = "REPORTS_UI_STOP_LOADING";
export const ONBOARDING_UI_START_LOADING = "ONBOARDING_UI_START_LOADING";
export const ONBOARDING_UI_STOP_LOADING = "ONBOARDING_UI_STOP_LOADING";
export const CREDIT_NOTES_UI_START_LOADING = "CREDIT_NOTES_UI_START_LOADING";
export const CREDIT_NOTES_UI_STOP_LOADING = "CREDIT_NOTES_UI_STOP_LOADING";
export const DEBIT_NOTES_UI_START_LOADING = "DEBIT_NOTES_UI_START_LOADING";
export const DEBIT_NOTES_UI_STOP_LOADING = "DEBIT_NOTES_UI_STOP_LOADING";
export const EXPENSES_UI_START_LOADING = "EXPENSES_UI_START_LOADING";
export const EXPENSES_UI_STOP_LOADING = "EXPENSES_UI_STOP_LOADING";
export const SALES_ORDERS_UI_START_LOADING = "SALES_ORDERS_UI_START_LOADING";
export const SALES_ORDERS_UI_STOP_LOADING = "SALES_ORDERS_UI_STOP_LOADING";
export const QUOTES_UI_START_LOADING = "QUOTES_UI_START_LOADING";
export const QUOTES_UI_STOP_LOADING = "QUOTES_UI_STOP_LOADING";
export const BANK_ACCOUNTS_UI_START_LOADING = "BANK_ACCOUNTS_UI_START_LOADING";
export const BANK_ACCOUNTS_UI_STOP_LOADING = "BANK_ACCOUNTS_UI_STOP_LOADING";
export const BANK_TRANSACTIONS_UI_START_LOADING =
  "BANK_TRANSACTIONS_UI_START_LOADING";
export const BANK_TRANSACTIONS_UI_STOP_LOADING =
  "BANK_TRANSACTIONS_UI_STOP_LOADING";
export const RECEIPTS_UI_START_LOADING = "RECEIPTS_UI_START_LOADING";
export const RECEIPTS_UI_STOP_LOADING = "RECEIPTS_UI_STOP_LOADING";
export const BANK_RECONCILIATION_UI_START_LOADING =
  "BANK_RECONCILIATION_UI_START_LOADING";
export const BANK_RECONCILIATION_UI_STOP_LOADING =
  "BANK_RECONCILIATION_UI_STOP_LOADING";
export const BANK_TRANSACTION_MATCH_UI_START_LOADING =
  "BANK_TRANSACTION_MATCH_UI_START_LOADING";
export const BANK_TRANSACTION_MATCH_UI_STOP_LOADING =
  "BANK_TRANSACTION_MATCH_UI_STOP_LOADING";

// Auth action types
export const SET_IDENTITY = "SET_IDENTITY";

// Business action types
export const SET_BUSINESSES = "SET_BUSINESSES";
export const SET_BUSINESS = "SET_BUSINESS";
export const SET_BUSINESS_SLUG = "SET_BUSINESS_SLUG";
export const SET_BUSINESS_LOGO = "SET_BUSINESS_LOGO"

// Accounts action types
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_ACCOUNT = "SET_ACCOUNT";

// Account Types action types
export const SET_ACCOUNT_TYPES = "SET_ACCOUNT_TYPES";
export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE";

// Account Categories action
export const SET_ACCOUNT_CATEGORIES = "SET_ACCOUNT_CATEGORIES";
export const SET_ACCOUNT_CATEGORY = "SET_ACCOUNT_CATEGORY";

// Global data action types
export const SET_TIMEZONES = "SET_TIMEZONES";
export const SET_COUNTRIES = "SET_COUNTRIES";

//Customer action types
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_CUSTOMERS = "SET_CUSTOMERS";

//Journal action types
export const SET_JOURNAL = "SET_JOURNAL";
export const SET_JOURNALS = "SET_JOURNALS";

//Product action types
export const SET_TRADEABLE = "SET_TRADEABLE";
export const SET_TRADEABLES = "SET_TRADEABLES";

//Services action types
export const SET_SERVICE = "SET_SERVICE";
export const SET_SERVICES = "SET_SERVICES";

//Tradeable categories action
export const SET_TRADEABLE_CATEGORIES = "SET_TRADEABLE_CATEGORIES";
export const SET_TRADEABLE_CATEGORY = "SET_TRADEABLE_CATEGORY";

//Suppliers action
export const SET_SUPPLIER = "SET_SUPPLIER";
export const SET_SUPPLIERS = "SET_SUPPLIERS";

//Bills action
export const SET_BILL = "SET_BILL";
export const SET_BILLS = "SET_BILLS";

//Purchase orders action
export const SET_PURCHASE_ORDER = "SET_PURCHASE_ORDER";
export const SET_PURCHASE_ORDERS = "SET_PURCHASE_ORDERS";

//Sales orders action
export const SET_SALES_ORDER = "SET_SALES_ORDER";
export const SET_SALES_ORDERS = "SET_SALES_ORDERS";

//Bill payments action
export const SET_BILL_PAYMENT = "SET_BILL_PAYMENT";
export const SET_BILL_PAYMENTS = "SET_BILL_PAYMENTS";

//Bill payments action
export const SET_INVOICE_PAYMENT = "SET_INVOICE_PAYMENT";
export const SET_INVOICE_PAYMENTS = "SET_INVOICE_PAYMENTS";

// Invoices actions
export const SET_INVOICE = "SET_INVOICE";
export const SET_INVOICES = "SET_INVOICES";

// Debit Notes actions
export const SET_DEBIT_NOTE = "SET_DEBIT_NOTE";
export const SET_DEBIT_NOTES = "SET_DEBIT_NOTES";

// Credit Note actions
export const SET_CREDIT_NOTE = "SET_CREDIT_NOTE";
export const SET_CREDIT_NOTES = "SET_CREDIT_NOTES";

// Credit Note actions
export const SET_EXPENSE = "SET_EXPENSE";
export const SET_EXPENSES = "SET_EXPENSES";

// Quotes actions
export const SET_QUOTE = "SET_QUOTE";
export const SET_QUOTES = "SET_QUOTES";

// Bank accounts actions
export const SET_BANK_ACCOUNT = "SET_BANK_ACCOUNT";
export const SET_BANK_ACCOUNTS = "SET_BANK_ACCOUNTS";

// Bank transactions actions
export const SET_BANK_TRANSACTION = "SET_BANK_TRANSACTION";
export const SET_BANK_TRANSACTIONS = "SET_BANK_TRANSACTIONS";

// Receipt actions
export const SET_RECEIPT = "SET_RECEIPT";
export const SET_RECEIPTS = "SET_RECEIPTS";

// BaNK Reconciliation actions
export const SET_BANK_RECONCILIATION = "SET_BANK_RECONCILIATION";
export const SET_BANK_RECONCILIATIONS = "SET_BANK_RECONCILIATIONS";

// BaNK Reconciliation actions
export const SET_BANK_TRANSACTION_MATCH = "SET_BANK_TRANSACTION_MATCH";
export const SET_BANK_TRANSACTION_MATCHES = "SET_BANK_TRANSACTION_MATCHES";

// RESETS
export const RESET_UI = "RESET_UI";
export const RESET_APP = "RESET_APP";
export const RESET_IDENTITY = "RESET_IDENTITY";
export const RESET_BUSINESSES = "RESET_BUSINESSES";
export const RESET_AUTH = "RESET_AUTH";
export const RESET_ACCOUNTS = "RESET_ACCOUNTS";
export const RESET_ACCOUNT_TYPES = "RESET_ACCOUNT_TYPES";
export const RESET_ACCOUNT_CATEGORIES = "RESET_ACCOUNT_CATEGORIES";
export const RESET_GLOBAL_DATA = "RESET_GLOBAL_DATA";
export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const RESET_JOURNAL = "RESET_JOURNAL";
export const RESET_TRADEABLE = "RESET_TRADEABLE";
export const RESET_SERVICE = "RESET_SERVICE";
export const RESET_TRADABLE_CATEGORY = "RESET_TRADABLE_CATEGORY";
export const RESET_SUPPLIER = "RESET_SUPPLIER";
export const RESET_BILL = "RESET_BILL";
export const RESET_PURCHASE_ORDER = "RESET_PURCHASE_ORDER";
export const RESET_SALES_ORDER = "RESET_SALES_ORDER";
export const RESET_BILL_PAYMENT = "RESET_BILL_PAYMENT";
export const RESET_INVOICE_PAYMENT = "RESET_INVOICE_PAYMENT";
export const RESET_TRADEABLE_CATEGORY = "RESET_TRADEABLE_CATEGORY";
export const RESET_CREDIT_NOTE = "RESET_CREDIT_NOTE";
export const RESET_DEBIT_NOTE = "RESET_DEBIT_NOTE";
export const RESET_EXPENSE = "RESET_EXPENSE";
export const RESET_BANK_ACCOUNT = "RESET_BANK_ACCOUNT";
export const RESET_BANK_TRANSACTION = "RESET_BANK_TRANSACTION";
export const RESET_RECEIPT = "RESET_RECEIPT";
export const RESET_RECONCILIATION = "RESET_RECONCILIATION";
export const RESET_TRANSACTION_MATCH = "RESET_TRANSACTION_MATCH";
