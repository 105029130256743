import { SET_BANK_ACCOUNT, SET_BANK_ACCOUNTS } from "../actions/actionTypes";

const initialState = {
  bankAccounts: [],
  bankAccountObject: {},
  bankAccount: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccounts: action.bankAccounts.data,
        bankAccountObject: action.bankAccounts,
      };
    case SET_BANK_ACCOUNT:
      return {
        ...state,
        bankAccount: {...state.bankAccount, ...action.bankAccount},
      };

    default:
      return state;
  }
};
