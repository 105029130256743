import {
    SET_BILL,
    SET_BILLS,
  } from "../actions/actionTypes";
  
  const initialState = {
    bills: [],
    billObject: {},
    bill: {}
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_BILLS:
        return {
          ...state,
          bills: action.bills.data,
          billObject: action.bills,
        };
        case SET_BILL:
          return {
            ...state,
            bill: action.bill,
          };

      default:
        return state;
    }
  };