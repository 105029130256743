import { SET_BILL_PAYMENT, SET_BILL_PAYMENTS } from "../actions/actionTypes";

const initialState = {
  billPayments: [],
  billPaymentObject: {},
  billPayment: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BILL_PAYMENTS:
      return {
        ...state,
        billPayments: action.billPayments.data,
        billPaymentObject: action.billPayments,
      };
    case SET_BILL_PAYMENT:
      return {
        ...state,
        billPayment: action.billPayment,
      };

    default:
      return state;
  }
};
