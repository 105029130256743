import {
  SET_TRADEABLE,
  SET_TRADEABLES,
} from "../actions/actionTypes";

const initialState = {
  tradeables: [],
  tradeableObject: {},
  tradeable: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TRADEABLES:
      return {
        ...state,
        tradeables: action.tradeables.data,
        tradeableObject: action.tradeables,
      };
      case SET_TRADEABLE:
        return {
          ...state,
          tradeable: action.tradeable,
        };

    default:
      return state;
  }
};