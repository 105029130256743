import { SET_SALES_ORDER, SET_SALES_ORDERS } from "../actions/actionTypes";

const initialState = {
  salesOrders: [],
  salesOrderObject: {},
  salesOrder: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SALES_ORDERS:
      return {
        ...state,
        salesOrders: action.salesOrders.data,
        salesOrderObject: action.salesOrders,
      };
    case SET_SALES_ORDER:
      return {
        ...state,
        salesOrder: action.salesOrder,
      };

    default:
      return state;
  }
};
