import {
  SET_INVOICE_PAYMENT,
  SET_INVOICE_PAYMENTS,
} from "../actions/actionTypes";

const initialState = {
  invoicePayments: [],
  invoicePaymentObject: {},
  invoicePayment: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_PAYMENTS:
      return {
        ...state,
        invoicePayments: action.invoicePayments.data,
        invoicePaymentObject: action.invoicePayments,
      };
    case SET_INVOICE_PAYMENT:
      return {
        ...state,
        invoicePayment: action.invoicePayment,
      };

    default:
      return state;
  }
};
