import { SET_DEBIT_NOTE, SET_DEBIT_NOTES } from "../actions/actionTypes";

const initialState = {
  debitNotes: [],
  debitNoteObject: {},
  debitNote: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DEBIT_NOTES:
      return {
        ...state,
        debitNotes: action.debitNotes.data,
        debitNoteObject: action.debitNotes,
      };
    case SET_DEBIT_NOTE:
      return {
        ...state,
        debitNote: action.debitNote,
      };

    default:
      return state;
  }
};
