import "flatpickr/dist/themes/dark.css";
import AppRoutes from "./routes";

  function App() {
    return (
      <div> 
        <AppRoutes /> 
      </div>
    );
  }

export default App;