import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./custom.scss";
import 'antd/dist/antd.min.css'
// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";
import geeboard_logo from "./assets/images/geeboard_logo.png"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <Suspense
      fallback={
        <div className="position-absolute start-50 top-50 translate-middle text-center">
          <img src={geeboard_logo} width="200" alt="Logo spinner" className="w-100" />
        </div>
      }
    >
      <App />
      </Suspense>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
