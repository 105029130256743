import {
  SET_BANK_RECONCILIATION,
  SET_BANK_RECONCILIATIONS,
} from "../actions/actionTypes";

const initialState = {
  bankReconciliations: [],
  bankReconciliationObject: {},
  bankReconciliation: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BANK_RECONCILIATIONS:
      return {
        ...state,
        bankReconciliations: action.bankReconciliations.data,
        bankReconciliationObject: action.bankReconciliations,
      };
    case SET_BANK_RECONCILIATION:
      return {
        ...state,
        bankReconciliation: action.bankReconciliation,
      };

    default:
      return state;
  }
};
