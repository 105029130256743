import { SET_CREDIT_NOTE, SET_CREDIT_NOTES } from "../actions/actionTypes";

const initialState = {
  creditNotes: [],
  creditNoteObject: {},
  creditNote: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CREDIT_NOTES:
      return {
        ...state,
        creditNotes: action.creditNotes.data,
        creditNoteObject: action.creditNotes,
      };
    case SET_CREDIT_NOTE:
      return {
        ...state,
        creditNote: action.creditNote,
      };

    default:
      return state;
  }
};
