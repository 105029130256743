import {
    SET_CUSTOMER,
    SET_CUSTOMERS,
  } from "../actions/actionTypes";
  
  const initialState = {
    customers: [],
    customerObject: {},
    customer: {}
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SET_CUSTOMERS:
        return {
          ...state,
          customers: action.customers.data,
          customerObject: action.customers,
        };
        case SET_CUSTOMER:
          return {
            ...state,
            customer: action.customer,
          };

      default:
        return state;
    }
  };