import { SET_JOURNAL, SET_JOURNALS } from "../actions/actionTypes";

const initialState = {
  journals: [],
  journalObject: {},
  journal: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_JOURNALS:
      return {
        ...state,
        journals: action.journals.data,
        journalObject: action.journals,
      };
    case SET_JOURNAL:
      return {
        ...state,
        journal: action.journal,
      };

    default:
      return state;
  }
};
